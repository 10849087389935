/* eslint-disable no-underscore-dangle */
import useSWR, { ConfigInterface, mutate } from 'swr';
import { localStorageFetcher } from '@api/local/fetcher';

// [Months count]: price per KW
type BatchElectricityPrices = {
  [2]: number | string;
  [3]: number | string;
  [6]: number | string;
  [12]: number | string;
};

export type Batch = {
  id: string;
  model: {
    id: string;
    model: string;
    nominalConsumption: number;
    image: string | null | undefined;
  };
  electricityPrices: BatchElectricityPrices;
  prices: {
    minQuantity: number;
    maxQuantity: number;
    price: number;
  }[];
  // Amount of hardware user can buy
  amount: number;
  trial?: boolean;
};

// BaseBasketItem object that is saved to storage
type StorageBasketItem = {
  // Computable, always equal to index in BasketItem array.
  // Is used to delete or patch item
  id: number;
  batch: Batch;
  monthElectricityCount: keyof BatchElectricityPrices;
  hardwareIds: string[];
  itemsCount: number;
};

// BaseBasketItem with additional computable fields
type BasketItem = StorageBasketItem & {
  // USD / KW ??
  monthlyElectricityCost: number;
  // USD, computable?
  electricityCost: number;
  itemsCost: number;
  totalCost: number;
};

// Basket saved to storage
export type StorageBasket = {
  items: StorageBasketItem[];

  discount_percentage?: number;
  discount_code?: string;
};

// Basket with additional computed fields
type Basket = {
  items: BasketItem[];
  electricityCost: number;
  itemsCost: number;
  itemsCount: number;
  totalCost: number;

  discount_code?: string;
};

const HOURS_IN_DAY = 24;
const DAYS_IN_MONTH = 30;

type GetBatchOrderPricesArg = {
  batch: Batch;
  monthElectricityCount: keyof BatchElectricityPrices;
  itemsCount: number;
};
export const getBatchOrderPrices = ({
  batch,
  monthElectricityCount,
  itemsCount,
}: GetBatchOrderPricesArg) => {
  const hourlyElectricityCost = +batch.electricityPrices[monthElectricityCount] / 1000;
  const hourlyElectricityCostMAX = +batch.electricityPrices['2'] / 1000;
  const monthlyElectricityCost = hourlyElectricityCost * HOURS_IN_DAY * DAYS_IN_MONTH;
  const monthlyElectricityCostMAX = hourlyElectricityCostMAX * HOURS_IN_DAY * DAYS_IN_MONTH;
  const electricityCost =
    itemsCount * monthlyElectricityCost * batch.model.nominalConsumption * monthElectricityCount;
  const electricityCostMAX =
    itemsCount * monthlyElectricityCostMAX * batch.model.nominalConsumption * monthElectricityCount;
  const itemsPriceRange = batch.prices.find(
    (priceInfo) => itemsCount >= priceInfo.minQuantity && itemsCount <= priceInfo.maxQuantity,
  );
  const itemPriceMAX = batch.prices[0].price;
  if (!itemsPriceRange || !itemPriceMAX) {
    throw new Error("Can't get price range for item");
  }
  const itemPrice = itemsPriceRange.price;
  const itemsCost = itemPrice * itemsCount;
  const itemsCostMAX = itemPriceMAX * itemsCount;
  const totalCost = electricityCost + itemsCost;
  const totalCostMAX = electricityCostMAX + itemsCostMAX;
  return {
    monthlyElectricityCost,
    monthlyElectricityCostMAX,
    electricityCost,
    electricityCostMAX,
    itemsCost,
    itemsCostMAX,
    totalCost,
    totalCostMAX,
  };
};
type GetMaxDiscountArg = {
  batch: Batch;
};
export const getMaxDiscount = ({ batch }: GetMaxDiscountArg) => {
  const hourlyElectricityCostMIN = +batch.electricityPrices['12'] / 1000;
  const hourlyElectricityCostMAX = +batch.electricityPrices['2'] / 1000;
  const monthlyElectricityCostMIN = hourlyElectricityCostMIN * HOURS_IN_DAY * DAYS_IN_MONTH;
  const monthlyElectricityCostMAX = hourlyElectricityCostMAX * HOURS_IN_DAY * DAYS_IN_MONTH;
  const electricityCostSuperMIN =
    batch.amount * monthlyElectricityCostMIN * batch.model.nominalConsumption * 12;
  const electricityCostSuperMAX =
    batch.amount * monthlyElectricityCostMAX * batch.model.nominalConsumption * 12;
  const itemPriceMIN = batch.prices[batch.prices.length - 1].price;
  const itemPriceMAX = batch.prices[0].price;
  const itemsCostSuperMIN = itemPriceMIN * batch.amount;
  const itemsCostSuperMAX = itemPriceMAX * batch.amount;
  const totalCostSuperMAX = electricityCostSuperMAX + itemsCostSuperMAX;
  const totalCostSuperMIN = electricityCostSuperMIN + itemsCostSuperMIN;
  return {
    totalCostSuperMAX,
    totalCostSuperMIN,
  };
};

type UseOrderListArg = {
  config?: ConfigInterface<StorageBasket | undefined>;
};
export const useOrderList = (arg?: UseOrderListArg) => {
  const info = useSWR<StorageBasket | undefined>(
    '_local/order-list',
    (key) => (key ? localStorageFetcher(key) : undefined),
    arg?.config,
  );
  let data: Basket | undefined;
  if (info.data) {
    const basketInfo = {
      electricityCost: 0,
      itemsCost: 0,
      itemsCount: info.data.items.length,
      totalCost: 0,
      discount_code: info.data.discount_code,
    };
    const basketItems = info.data.items.map((storageItem) => {
      const basketItem: BasketItem = {
        ...storageItem,
        ...getBatchOrderPrices(storageItem),
      };
      basketInfo.electricityCost += basketItem.electricityCost;
      basketInfo.itemsCost += basketItem.itemsCost;
      basketInfo.totalCost += basketItem.totalCost;
      return basketItem;
    });

    data = {
      items: basketItems,
      ...basketInfo,
    };
  } else {
    data = info.data;
  }

  return {
    ...info,
    loading: false,
    data,
  };
};

type CreateOrderArg = Omit<StorageBasketItem, 'id'>;
export const createOrder = (arg: CreateOrderArg) => {
  mutate(
    '_local/order-list',
    () => {
      // Get current basket
      const basketStorageData = localStorage.getItem('_local/order-list');
      const basket: StorageBasket = basketStorageData ? JSON.parse(basketStorageData) : {};
      const currentItems = basket?.items || [];

      // Create new item with id and add it to new basket
      const newItemId = currentItems.length;
      const newBasketItem = { ...arg, id: newItemId };
      const updatedItems = [...currentItems, newBasketItem];
      const updatedBasket = { ...basket, items: updatedItems };

      // Save updated basket
      localStorage.setItem('_local/order-list', JSON.stringify(updatedBasket));
    },
    true,
  );
};

type PatchOrderArg = {
  itemId: number;
  monthElectricityCount: 2 | 3 | 6 | 12;
  itemsCount: number;
};
export const patchOrder = ({ itemId, monthElectricityCount, itemsCount }: PatchOrderArg) => {
  mutate(
    '_local/order-list',
    () => {
      // Get current basket
      const basketStorageData = localStorage.getItem('_local/order-list');
      const basket: StorageBasket = basketStorageData ? JSON.parse(basketStorageData) : {};

      const itemToPatch = basket.items[itemId];
      if (!itemToPatch) {
        throw new Error('Attempt to patch non existing item');
      }
      if (itemsCount > itemToPatch.batch.amount) {
        throw new Error('Attempt to purchase more items than available');
      }

      // We can patch object, because JSON.parse return copy of
      // basket
      basket.items[itemId] = {
        ...itemToPatch,
        monthElectricityCount,
        itemsCount,
      };

      localStorage.setItem('_local/order-list', JSON.stringify(basket));
    },
    true,
  );

  return undefined;
};

export const removeOrder = (id: BasketItem['id']) => {
  mutate(
    '_local/order-list',
    () => {
      // Get current basket
      const basketStorageData = localStorage.getItem('_local/order-list');
      const basket: StorageBasket = basketStorageData ? JSON.parse(basketStorageData) : {};

      // Remove element and update ids
      basket.items.splice(id, 1);
      basket.items = basket.items.map((item, index) => ({
        ...item,
        id: index,
      }));

      localStorage.setItem('_local/order-list', JSON.stringify(basket));
    },
    true,
  );
};

export const purgeBasket = () => {
  mutate(
    '_local/order-list',
    () => {
      localStorage.removeItem('_local/order-list');
    },
    true,
  );
};
