import React, { ReactNode } from 'react';

// Styles
import Heading from '@components/layout/Heading/Heading';
import styles from './content.module.sass';

// Props
type ContentProps = {
  isHeading?: boolean;
  children: ReactNode;
  title: string;
  breadCrumbs?: {
    title: string;
    link?: string;
  }[];
  viewAccount?: string;
  setShownMenu?: (arg: boolean) => void;
};

const Content: React.FC<ContentProps> = ({
  isHeading = true,
  children,
  title,
  breadCrumbs,
  viewAccount,
  setShownMenu,
}) => (
  <div className={`${styles.content} content`}>
    {isHeading && (
      <Heading
        title={title}
        viewAccount={viewAccount}
        breadCrumbs={breadCrumbs}
        setShownMenu={setShownMenu}
      />
    )}
    {children}
  </div>
);
export default Content;
