import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { getJWTInfo, parseCookie } from '@utils/api';
import { RoleEnum } from '@api/types';
import { useFullProfile } from '@api/users';

// Components
import Meta from '@ui/Meta/Meta';
import Content from '../content/Content';
import NavBar from '../navBar/NavBar';

// Data
import {
  navButtonsUser,
  navButtonsReseller,
  navButtonsUserZh,
  navButtonsResellerZh,
} from './navButtons';
import { useCurrentTheme, THEME } from '@api/local/theme';
import { useMounted } from '@utils/hooks/useMounted';

// Props
type LayoutMenuProps = {
  isHeading?: boolean;
  children: ReactNode;
  title?: string;
  seoTitle?: string;
  breadCrumbs?: {
    title: string;
    link?: string;
  }[];
  viewAccount?: string;
};

const LayoutMenu: React.FC<LayoutMenuProps> = ({
  isHeading,
  children,
  title,
  breadCrumbs,
  viewAccount,
  seoTitle,
}) => {
  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  const { i18n } = useTranslation();
  const mounted = useMounted();
  const [shownMenu, setShownMenu] = useState(false);

  let profileArg:
    | {
        userId: string;
        skip?: undefined;
      }
    | {
        skip: true;
        userId?: undefined;
      } = { skip: true as const };

  if (mounted) {
    const cookie = parseCookie(document.cookie);
    if (cookie.token) {
      const jwtInfo = getJWTInfo();
      profileArg = jwtInfo ? { userId: jwtInfo.user_id } : { skip: true as const };
    }
  }

  const res = useFullProfile(profileArg);

  if (res.error) {
    throw res.error;
  }

  let navButtons: {
    path: string;
    label: string;
    icon: React.ReactNode;
    iconActive: React.ReactNode;
    onClick?: () => void;
  }[] = [];
  if (i18n.language === 'en') {
    navButtons =
      res?.data?.role === RoleEnum.Reseller
        ? navButtonsReseller(isLightTheme)
        : navButtonsUser(isLightTheme);
  } else if (i18n.language === 'zh') {
    navButtons =
      res?.data?.role === RoleEnum.Reseller
        ? navButtonsResellerZh(isLightTheme)
        : navButtonsUserZh(isLightTheme);
  }

  if (!mounted) {
    return <>{seoTitle ? <Meta title={seoTitle} /> : <Meta />}</>;
  }

  return (
    <>
      <NavBar navButtons={navButtons} shownMenu={shownMenu} setShownMenu={setShownMenu} />
      {title ? <Meta title={seoTitle} /> : <Meta />}
      <Content
        isHeading={isHeading}
        viewAccount={viewAccount}
        title={title || ''}
        breadCrumbs={breadCrumbs}
        setShownMenu={setShownMenu}
      >
        {children}
      </Content>
    </>
  );
};

export default LayoutMenu;
