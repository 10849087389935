import React, { useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ReactModal from 'react-modal';
import Cookies from 'js-cookie';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';

// Api
import { getJWTInfo } from '@utils/api';

// Components
import StyledCard from '@ui/styledCard/StyledCard';
import Button from '@ui/button/Button';

// Icons
import Logout from '@icons/menu/Logout.svg';
import LogoutActive from '@icons/menu/LogoutActive.svg';
import LogoutActiveDarkTheme from '@icons/menu/LogoutActiveDarkTheme.svg';
import Zionodes from '@icons/menu/zionodesPronounced.svg';
import ZionodesLogoDarkTheme from '@icons/ZionodesLogoDarkPronounced.svg';
import ThemeSwitch from '@icons/menu/themeSwitch.svg';
import ThemeSwitchLight from '@icons/menu/themeSwitchLight.svg';
import Cross from '@icons/ui-kit/Cross.svg';
import NavButton from './NavButton/NavButton';

// Styles
import styles from './NavBar.module.sass';
import { THEME, useCurrentTheme } from '@api/local/theme';
import { changeTheme } from '@utils/themeChanger';
import { useLtabletOrWider } from '@utils/mediaQuery';

// Props
type NavButtonProps = {
  path: string;
  label: string;
  isHidden?: boolean;
  icon: React.ReactNode;
  iconActive: React.ReactNode;
  onClick?: () => void;
};
type NavBarProps = {
  navButtons: NavButtonProps[];
  shownMenu?: boolean;
  setShownMenu?: (arg: boolean) => void;
};

const NavBar: React.FC<NavBarProps> = ({ navButtons, shownMenu = false, setShownMenu }) => {
  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  const { t } = useTranslation('common');
  const router = useRouter();
  const isLtabletOrWider = useLtabletOrWider();
  const [modalLogout, setModalLogout] = useState(false);

  const theme = useCurrentTheme();
  const themeChangerRef = useRef<HTMLDivElement>(null);

  if (typeof window === 'undefined') {
    return null;
  }
  const token = getJWTInfo();

  const handleChangeTheme = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = (
      themeChangerRef.current?.querySelector('svg') as SVGElement
    ).getBoundingClientRect();
    const x = e.clientX - rect?.left; //x position within the element.
    const y = e.clientY - rect?.top; //x position within the element.

    // Change theme only if user clicked on children SVG element
    if (x - rect.width < 0 && x > 0 && y > 0) {
      const newTheme = x - rect.width / 2 > 0 ? THEME.dark : THEME.light;

      changeTheme(newTheme);
    }
  };

  return (
    <>
      {!isLtabletOrWider && (
        <div
          className={cx(styles.overlay, { [styles.active]: shownMenu })}
          onClick={() => setShownMenu && setShownMenu(false)}
        />
      )}
      <div className={cx(styles.navBar, { [styles.active]: shownMenu })}>
        {!isLtabletOrWider && (
          <div className={styles.closeButton}>
            <Button
              theme="flexible ternary superSmall"
              onClick={() => setShownMenu && setShownMenu(false)}
            >
              <Cross />
            </Button>
          </div>
        )}
        <div className={styles.row}>
          <Link href="/marketplace">
            <a href="/marketplace" className={styles.logo}>
              {isLightTheme ? <Zionodes /> : <ZionodesLogoDarkTheme />}
            </a>
          </Link>
          <nav className={styles.nav}>
            {navButtons.map((button) =>
              !token ? (
                !button.isHidden && (
                  <NavButton
                    key={button.path}
                    path={button.path}
                    label={button.label}
                    icon={button.icon}
                    iconActive={button.iconActive}
                  />
                )
              ) : (
                <NavButton
                  key={button.path}
                  path={button.path}
                  label={button.label}
                  icon={button.icon}
                  iconActive={button.iconActive}
                />
              ),
            )}
            {token && (
              <NavButton
                icon={<Logout />}
                iconActive={isLightTheme ? <LogoutActive /> : <LogoutActiveDarkTheme />}
                label={t('Notation.logout')}
                onClick={() => setModalLogout(true)}
                isLast
              />
            )}
          </nav>
          <div
            onClick={handleChangeTheme}
            className={cx(styles.themeChanger, {
              [styles.themeChangerLight]: theme.data?.theme === THEME.light,
            })}
            ref={themeChangerRef}
          >
            <p className={styles.themeChangerText}>{t('Notation.changeTheme')}</p>
            {isLightTheme ? <ThemeSwitchLight /> : <ThemeSwitch />}
          </div>
        </div>
      </div>
      <ReactModal
        appElement={
          typeof window !== 'undefined'
            ? (document.querySelector('body') as HTMLElement)
            : undefined
        }
        isOpen={modalLogout}
        overlayClassName="Modal__Overlay"
        className="Modal__Content"
        onRequestClose={() => setModalLogout(false)}
      >
        <StyledCard theme="reverse">
          <div className={styles.modalContent}>
            <h2>{t('Notifications.Logout.sure')}</h2>
            <div className={styles.modalButtons}>
              <div className={styles.modalButton}>
                <Button theme="middle" onClick={() => setModalLogout(false)}>
                  {t('Notifications.Logout.stay')}
                </Button>
              </div>
              <div className={styles.modalButton}>
                <Button
                  theme="middle ternary"
                  onClick={() => {
                    Cookies.remove('token');
                    router.push('/login');
                  }}
                >
                  {t('Notifications.Logout.yes')}
                </Button>
              </div>
            </div>
          </div>
        </StyledCard>
      </ReactModal>
    </>
  );
};

export default NavBar;
