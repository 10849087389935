import React from 'react';
import Link from 'next/link';
import cx from 'classnames';

// Api
import { IMAGES_URL } from '@utils/constants';
import { getJWTInfo } from '@utils/api';
import { useFullProfile } from '@api/users';
import { RoleEnum } from '@api/types';
import { useOrderList } from '@api/local/basket';

// Components
import Button from '@ui/button/Button';

// Icons
import FaqIcon from '@icons/ui-kit/faq.svg';
import PackageIcon from '@icons/ui-kit/package.svg';
import ArrowIcon from '@icons/bread-crumbs-arrow.svg';
import PlaceholderUser from '@icons/placeholders/placeholder-user-small.svg';

// Styles
import CustomLink from '@ui/customLink/CustomLink';
import { useTranslation } from 'next-i18next';
import { useLphoneOrWider, useLtabletOrWider } from '@utils/mediaQuery';
import styles from './Heading.module.sass';
import { useCurrentTheme, THEME } from '@api/local/theme';

// Props
type HeadingProps = {
  title: string;
  breadCrumbs?: {
    title: string;
    link?: string;
  }[];
  viewAccount?: string;
  setShownMenu?: (arg: boolean) => void;
};

const Heading: React.FC<HeadingProps> = ({ title, breadCrumbs, viewAccount, setShownMenu }) => {
  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  const { t } = useTranslation('common');
  const isLtabletOrWider = useLtabletOrWider();
  const isLphoneOrWider = useLphoneOrWider();

  const jwtInfo = getJWTInfo();
  const profileArg = jwtInfo ? { userId: jwtInfo.user_id } : { skip: true as const };

  const { data, error } = useFullProfile(profileArg);

  if (error) {
    throw error;
  }

  let displayName = data ? `${data?.first_name} ${data?.last_name}` : '';
  displayName = displayName.length > 15 ? `${displayName.substring(0, 15)}...` : displayName;

  const { data: dataOrder, error: errorOrder } = useOrderList();

  if (errorOrder) {
    throw errorOrder;
  }

  const buttons = [];

  if (viewAccount && data?.role === RoleEnum.Reseller) {
    buttons.push(
      <div key={0} className={styles.buttonView}>
        <Button theme="flexible" link={`/resellers/${viewAccount}`} external>
          {t('Buttons.viewAccount.default')}
        </Button>
      </div>,
    );
  } else {
    if (data?.role === RoleEnum.User) {
      buttons.push(
        <div key={1} className={cx(styles.btn, { [styles.active]: !!dataOrder?.items.length })}>
          <Button theme="ternary superSmall" link="/order-list">
            <PackageIcon />
          </Button>
        </div>,
      );
    } else if (!data?.role) {
      buttons.push(
        <div key={2} className={styles.buttons}>
          {isLphoneOrWider ? (
            <>
              <CustomLink link="/login" className={styles.login}>
                {t('Buttons.login.default')}
              </CustomLink>
              <div className={styles.signupButton}>
                <Button theme="flexible" link="/signup">
                  {t('Buttons.signUp.default')}
                </Button>
              </div>
            </>
          ) : (
            <div className={styles.loginPhoneButton}>
              <Button theme="flexible" link="/signup">
                {t('Buttons.signUp.default')}
              </Button>
            </div>
          )}
        </div>,
      );
    }
    buttons.push(
      <div key={3} className={styles.btn}>
        <Button theme="ternary superSmall" link="https://zionodes.zendesk.com/hc/en-us" external>
          <FaqIcon />
        </Button>
      </div>,
    );
    if (displayName) {
      buttons.push(
        <Link prefetch={false} key={4} href="/settings">
          <a
            className={cx(styles.button, styles.personButton)}
            title={`${data?.first_name} ${data?.last_name}`}
          >
            <span className={styles.content}>
              <span className={styles.person}>
                {data?.image ? (
                  <img src={IMAGES_URL + data.image} alt={`${data.first_name}'s`} />
                ) : (
                  <span className={styles.personPlaceholder}>
                    <PlaceholderUser />
                  </span>
                )}
              </span>

              {isLphoneOrWider && displayName}
            </span>
          </a>
        </Link>,
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        {!isLtabletOrWider && (
          <button
            className={cx(styles.buttonShowMenu, {
              [styles.buttonShowMenuDatkTheme]: !isLightTheme,
            })}
            onClick={() => setShownMenu && setShownMenu(true)}
            type="button"
          >
            <span />
          </button>
        )}
        <div className={styles.head}>
          <h1>{title}</h1>
        </div>
        <div className={styles.buttonsWrapper}>
          {buttons}
          {/* <LanguageChange className={styles.languages} /> */}
        </div>
      </div>
      <div className={styles.breadCrumbs}>
        {breadCrumbs?.map((el) =>
          el.link ? (
            <span key={`${el.title}-${el.link}`} className={styles.breadCrumbsItem}>
              <Link prefetch={false} href={el.link}>
                <a className={styles.breadCrumbsLink}>{el.title}</a>
              </Link>
              <ArrowIcon />
            </span>
          ) : (
            <span
              key={`${el.title}-${el.link}`}
              className={cx(styles.breadCrumbsItem, styles.breadCrumbsItemActive)}
            >
              {el.title}
            </span>
          ),
        )}
      </div>
    </div>
  );
};

export default Heading;
