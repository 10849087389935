import { GenericValidationErrors } from '@types';
import {
  JwtData,
  PatchUserCompanyResult,
  PatchUserLegalRepresentativeParams,
  PatchUserLegalRepresentativeResult,
  PatchUserParams,
  PatchUserProfileCompanyParams,
  PatchUserProfileParams,
  PatchUserProfileResult,
  PatchUserProfileSpecialParams,
  PatchUserResult,
  PatchUserSpecialResult,
  ProfileAdditionalType,
  ProfileCompanyType,
  ProfileLegalRepresentativeType,
  ProfileSpecialType,
  ProfileType,
  UserInfo,
  WidthdawConfirmType,
} from '@api/types';
import { api, genericFetcher } from '@api/fetcher';
import useSWR, { ConfigInterface, mutate } from 'swr';

type PatchUserArg = {
  userId: string;
  params: PatchUserParams;
};
type PatchUserReturn = {
  data: PatchUserResult | undefined;
  errors:
    | GenericValidationErrors<PatchUserParams['data'] & Pick<PatchUserParams, 'image'>>
    | undefined;
};
export const patchUser = async ({ userId, params }: PatchUserArg): Promise<PatchUserReturn> => {
  const formData = new FormData();
  if (params.image) {
    formData.append('image', params.image);
  } else if (params.image === null) {
    formData.append('image', '');
  }
  formData.append('data', JSON.stringify(params.data));

  const response = await api.makeRequest<PatchUserReturn['data'] | PatchUserReturn['errors']>({
    method: 'PATCH',
    url: `users/${userId}/`,
    requestData: formData,
    options: {
      headers: { 'content-type': 'multipart/form-data' },
    },
  });

  const data = response.status === 200 ? (response.data as PatchUserReturn['data']) : undefined;
  const errors = response.status === 400 ? (response.data as PatchUserReturn['errors']) : undefined;

  if (response.status === 200) {
    mutate(`users/${userId}/`, data, false);
    mutate(
      `users/${userId}/full-profile/`,
      (profile: ProfileType) => (profile ? Object.assign(profile, data) : profile),
      true,
    );
  }

  return {
    data,
    errors,
  };
};

type PatchUserProfileArg = {
  userId: string;
  params: PatchUserProfileParams;
};
type PatchUserProfileReturn = {
  data: PatchUserProfileResult | undefined;
  errors: GenericValidationErrors<PatchUserProfileParams> | undefined;
};

export const patchUserProfile = async ({
  userId,
  params,
}: PatchUserProfileArg): Promise<PatchUserProfileReturn> => {
  const response = await api.makeRequest<
    PatchUserProfileReturn['data'] | PatchUserProfileReturn['errors']
  >({
    method: 'PATCH',
    url: `users/${userId}/profile/`,
    requestData: params,
  });

  const data =
    response.status === 200 ? (response.data as PatchUserProfileReturn['data']) : undefined;
  const errors =
    response.status === 400 ? (response.data as PatchUserProfileReturn['errors']) : undefined;
  if (response.status === 200) {
    mutate(`users/${userId}/profile/`, data, false);
  }
  return {
    data,
    errors,
  };
};

type PostNewPasswordArg = {
  params: {
    old_password: string;
    new_password1: string;
    new_password2: string;
  };
};
export const postNewPassword = async ({ params }: PostNewPasswordArg) => {
  const response = await api.makeRequest({
    method: 'POST',
    url: 'auth/change-password/',
    requestData: params,
  });

  const data = response.status === 200 ? response.data : undefined;
  const errors = response.status === 400 ? response.data : undefined;

  return {
    data,
    errors,
  };
};

type PatchUserProfileSpecialArg = {
  userId: string;
  params: PatchUserProfileSpecialParams;
};
type PatchUserProfileSpecialReturn = {
  data: PatchUserSpecialResult | undefined;
  errors:
    | GenericValidationErrors<PatchUserProfileParams & Pick<PatchUserParams, 'image'>>
    | undefined;
};

export const patchUserSpecialProfile = async ({ userId, params }: PatchUserProfileSpecialArg) => {
  const formData = new FormData();
  // TODO (lnalkman): rewrite logic after backend reset image implementation
  if (params.image) {
    formData.append('image', params.image);
  } else if (params.image === null) {
    formData.append('image', '');
  }
  formData.append('data', JSON.stringify(params.data));

  const response = await api.makeRequest({
    method: 'PATCH',
    url: `users/${userId}/special-profile/`,
    requestData: formData,
    options: {
      headers: { 'content-type': 'multipart/form-data' },
    },
  });

  const data =
    response.status === 200 ? (response.data as PatchUserProfileSpecialReturn['data']) : undefined;
  const errors =
    response.status === 400
      ? (response.data as PatchUserProfileSpecialReturn['errors'])
      : undefined;
  if (response.status === 200) {
    mutate(`users/${userId}/special-profile/`, data, false);
  }
  return {
    data,
    errors,
  };
};

type PatchUserProfileCompanyArg = {
  userId: string;
  params: PatchUserProfileCompanyParams;
};
type PatchUserProfileCompanyReturn = {
  data: PatchUserCompanyResult | undefined;
  errors: GenericValidationErrors<PatchUserProfileCompanyParams> | undefined;
};

export const patchUserCompanyProfile = async ({
  userId,
  params,
}: PatchUserProfileCompanyArg): Promise<PatchUserProfileCompanyReturn> => {
  const response = await api.makeRequest<
    PatchUserProfileCompanyReturn['data'] | PatchUserProfileCompanyReturn['errors']
  >({
    method: 'PATCH',
    url: `users/${userId}/company-special-profile/`,
    requestData: params,
  });

  const data =
    response.status === 200 ? (response.data as PatchUserProfileCompanyReturn['data']) : undefined;
  const errors =
    response.status === 400
      ? (response.data as PatchUserProfileCompanyReturn['errors'])
      : undefined;
  if (response.status === 200) {
    mutate(`users/${userId}/company-special-profile/`, data, false);
  }
  return {
    data,
    errors,
  };
};

type PatchUserLegalRepresentativeArg = {
  userId: string;
  params: PatchUserLegalRepresentativeParams;
};
type PatchUserLegalRepresentativeReturn = {
  data: PatchUserLegalRepresentativeResult | undefined;
  errors: GenericValidationErrors<PatchUserLegalRepresentativeParams> | undefined;
};

export const patchUserLegalRepresentative = async ({
  userId,
  params,
}: PatchUserLegalRepresentativeArg): Promise<PatchUserLegalRepresentativeReturn> => {
  const response = await api.makeRequest<
    PatchUserLegalRepresentativeReturn['data'] | PatchUserLegalRepresentativeReturn['errors']
  >({
    method: 'PATCH',
    url: `users/${userId}/legal-representative-profile/`,
    requestData: params,
  });

  const data =
    response.status === 200
      ? (response.data as PatchUserLegalRepresentativeReturn['data'])
      : undefined;
  const errors =
    response.status === 400
      ? (response.data as PatchUserLegalRepresentativeReturn['errors'])
      : undefined;
  if (response.status === 200) {
    mutate(`users/${userId}/legal-representative-profile/`, data, false);
  }
  return {
    data,
    errors,
  };
};

type UseUserInfoArg = {
  userId: string;
  config?: ConfigInterface<UserInfo>;
};
export const useUserInfo = ({ userId, config }: UseUserInfoArg) => {
  const info = useSWR<UserInfo>(`users/${userId}/`, genericFetcher(), config);

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

type UseFullProfileArg = {
  config?: ConfigInterface<ProfileType>;
} & ({ userId: string; skip?: boolean } | { userId?: never; skip: true });
export const useFullProfile = ({ userId, config, skip }: UseFullProfileArg) => {
  const info = useSWR<ProfileType>(
    skip ? null : `users/${userId}/full-profile/`,
    genericFetcher(),
    { ...config, revalidateOnFocus: false },
  );

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

type UseAdditionalProfileArg = {
  userId: string;
};
export const useAdditionalProfile = ({ userId }: UseAdditionalProfileArg) => {
  const info = useSWR<ProfileAdditionalType>(`users/${userId}/profile/`, genericFetcher());

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

type UseSpecialProfileArg = {
  userId: string;
  config?: ConfigInterface<ProfileSpecialType>;
};
export const useSpecialProfile = ({ userId, config }: UseSpecialProfileArg) => {
  const info = useSWR<ProfileSpecialType>(
    `users/${userId}/special-profile/`,
    genericFetcher(),
    config,
  );

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

type UseCompanyProfileArg = {
  userId: string;
};
export const useCompanyProfile = ({ userId }: UseCompanyProfileArg) => {
  const info = useSWR<ProfileCompanyType>(
    `users/${userId}/company-special-profile/`,
    genericFetcher(),
  );

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

type UseLegalRepresentativeProfileArg = {
  userId: string;
};
export const useLegalRepresentativeProfile = ({ userId }: UseLegalRepresentativeProfileArg) => {
  const info = useSWR<ProfileLegalRepresentativeType>(
    `users/${userId}/legal-representative-profile/`,
    genericFetcher(),
  );

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

export type PasswordUpdateType = {
  password: string;
  token: string;
};

export const updateUserPassword = async ({ password, token }: PasswordUpdateType) =>
  api.makeRequest({
    method: 'POST',
    url: 'auth/reset-password/confirm/',
    requestData: { password, token },
  });

type ResetPasswordType = {
  email: string;
};

export const sendResetPassword = async ({ email }: ResetPasswordType) =>
  api.makeRequest({
    method: 'POST',
    url: 'auth/reset-password/',
    requestData: { email },
  });

type ResendConfirmationEmailType = {
  email: string;
};

export const resendConfirmationEmail = async ({ email }: ResendConfirmationEmailType) =>
  api.makeRequest({
    method: 'POST',
    url: 'auth/resend-activation-email/',
    requestData: { email },
  });

export type ConfirmEmailType = {
  token: string;
  id: string;
};

export const confirmEmail = ({ token, id }: ConfirmEmailType) => {
  const info = useSWR<JwtData>(`auth/confirm-mail-change/${id}/${token}/`, genericFetcher());

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

export const cancelEmailChange = async ({ token, id }: ConfirmEmailType) => {
  const info = useSWR<JwtData>(`auth/cancel-mail-change/${id}/${token}/`, genericFetcher());

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

export const withdrawalConfirm = async ({ token, id }: ConfirmEmailType) => {
  const info = useSWR<WidthdawConfirmType>(
    `b2b_payments/withdrawal-confirm/${id}/${token}/`,
    genericFetcher(),
  );

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

export const confirmEmailChange = async ({ token, id }: ConfirmEmailType) => {
  const info = useSWR<JwtData>(`auth/confirm-mail-change/${id}/${token}/`, genericFetcher());

  return {
    loading: !info.error && !info.data,
    ...info,
  };
};

export const validateNextRegistration = async (values: any) =>
  api.makeRequest({
    url: 'auth/reseller-company-signup-first-part-validate/',
    method: 'POST',
    requestData: values,
  });
