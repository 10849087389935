import React, { ReactNode } from 'react';

// Icons for Users
import Marketplace from '../../../public/icons/menu/Marketplace.svg';
import MarketplaceActive from '../../../public/icons/menu/MarketplaceActive.svg';
import MarketplaceActiveDarkTheme from '../../../public/icons/menu/MarketplaceActiveDarkTheme.svg';
import FarmOperators from '../../../public/icons/menu/FarmOperators.svg';
import FarmOperatorsActive from '../../../public/icons/menu/FarmOperatorsActive.svg';
import FarmOperatorsActiveDarkTheme from '../../../public/icons/menu/FarmOperatorsActiveDarkTheme.svg';
import MyHardware from '../../../public/icons/menu/MyHardware.svg';
import MyHardwareActive from '../../../public/icons/menu/MyHardwareActive.svg';
import MyHardwareActiveDarkTheme from '../../../public/icons/menu/MyHardwareActiveDarkTheme.svg';
import Dashboard from '../../../public/icons/menu/Dashboard.svg';
import DashboardActive from '../../../public/icons/menu/DashboardActive.svg';
import DashboardActiveDarkTheme from '../../../public/icons/menu/DashboardActiveDarkTheme.svg';

// Icons for Resellers
import Stock from '../../../public/icons/menu/Stock.svg';
import StockActive from '../../../public/icons/menu/StockActive.svg';
import StockActiveDarkTheme from '../../../public/icons/menu/StockActiveDarkTheme.svg';
import InUse from '../../../public/icons/menu/InUse.svg';
import InUseActive from '../../../public/icons/menu/InUseActive.svg';
import InUseActiveDarkTheme from '../../../public/icons/menu/InUseActiveDarkTheme.svg';
import MyFinance from '../../../public/icons/menu/MyFinance.svg';
import MyFinanceActive from '../../../public/icons/menu/MyFinanceActive.svg';
import MyFinanceActiveDarkTheme from '../../../public/icons/menu/MyFinanceActiveDarkTheme.svg';

// Icons for Both
import Billing from '../../../public/icons/menu/Billing.svg';
import BillingActive from '../../../public/icons/menu/BillingActive.svg';
import BillingActiveDarkTheme from '../../../public/icons/menu/BillingActiveDarkTheme.svg';
import Settings from '../../../public/icons/menu/Settings.svg';
import SettingsActive from '../../../public/icons/menu/SettingsActive.svg';
import SettingsActiveDarkTheme from '../../../public/icons/menu/SettingsActiveDarkTheme.svg';

// Nav buttons for Users
export const navButtonsUser: (isLightTheme: boolean) => {
  label: string;
  path: string;
  icon: ReactNode;
  isHidden?: boolean;
  iconActive: ReactNode;
  onClick?: () => void;
}[] = (isLightTheme) => [
  {
    label: 'Marketplace',
    path: '/marketplace',
    icon: <Marketplace />,
    iconActive: isLightTheme ? <MarketplaceActive /> : <MarketplaceActiveDarkTheme />,
  },
  {
    label: 'Farm Operators',
    path: '/farm-operators',
    icon: <FarmOperators />,
    iconActive: isLightTheme ? <FarmOperatorsActive /> : <FarmOperatorsActiveDarkTheme />,
  },
  {
    label: 'My Hardware',
    path: '/my-hardware',
    icon: <MyHardware />,
    iconActive: isLightTheme ? <MyHardwareActive /> : <MyHardwareActiveDarkTheme />,
  },
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <Dashboard />,
    iconActive: isLightTheme ? <DashboardActive /> : <DashboardActiveDarkTheme />,
  },
  {
    label: 'Billing',
    path: '/billing',
    icon: <Billing />,
    iconActive: isLightTheme ? <BillingActive /> : <BillingActiveDarkTheme />,
  },
  {
    label: 'Settings',
    path: '/settings',
    icon: <Settings />,
    iconActive: isLightTheme ? <SettingsActive /> : <SettingsActiveDarkTheme />,
  },
];

// Nav buttons for Resellers
export const navButtonsReseller: (isLightTheme: boolean) => {
  label: string;
  path: string;
  icon: ReactNode;
  iconActive: ReactNode;
  onClick?: () => void;
}[] = (isLightTheme) => [
  {
    label: 'Stock',
    path: '/stock',
    icon: <Stock />,
    iconActive: isLightTheme ? <StockActive /> : <StockActiveDarkTheme />,
  },
  {
    label: 'In Use',
    path: '/in-use',
    icon: <InUse />,
    iconActive: isLightTheme ? <InUseActive /> : <InUseActiveDarkTheme />,
  },
  {
    label: 'My Finance',
    path: '/my-finance',
    icon: <MyFinance />,
    iconActive: isLightTheme ? <MyFinanceActive /> : <MyFinanceActiveDarkTheme />,
  },
  {
    label: 'Settings',
    path: '/settings',
    icon: <Settings />,
    iconActive: isLightTheme ? <SettingsActive /> : <SettingsActiveDarkTheme />,
  },
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <Dashboard />,
    iconActive: isLightTheme ? <DashboardActive /> : <DashboardActiveDarkTheme />,
  },
];

// Nav buttons for Users
export const navButtonsUserZh: (isLightTheme: boolean) => {
  label: string;
  path: string;
  isHidden?: boolean;
  icon: ReactNode;
  iconActive: ReactNode;
  onClick?: () => void;
}[] = (isLightTheme) => [
  {
    label: '市场',
    path: '/marketplace',
    icon: <Marketplace />,
    iconActive: isLightTheme ? <MarketplaceActive /> : <MarketplaceActiveDarkTheme />,
  },
  {
    label: '矿场运维商',
    path: '/farm-operators',
    icon: <FarmOperators />,
    iconActive: isLightTheme ? <FarmOperatorsActive /> : <FarmOperatorsActiveDarkTheme />,
  },
  {
    label: '我的硬件',
    path: '/my-hardware',
    icon: <MyHardware />,
    iconActive: isLightTheme ? <MyHardwareActive /> : <MyHardwareActiveDarkTheme />,
  },
  {
    label: '仪表盘',
    path: '/dashboard',
    icon: <Dashboard />,
    iconActive: isLightTheme ? <DashboardActive /> : <DashboardActiveDarkTheme />,
  },
  {
    label: '账单',
    path: '/billing',
    icon: <Billing />,
    iconActive: isLightTheme ? <BillingActive /> : <BillingActiveDarkTheme />,
  },
  {
    label: '设置',
    path: '/settings',
    icon: <Settings />,
    iconActive: isLightTheme ? <SettingsActive /> : <SettingsActiveDarkTheme />,
  },
];

// Nav buttons for Resellers
export const navButtonsResellerZh: (isLightTheme: boolean) => {
  label: string;
  path: string;
  icon: ReactNode;
  iconActive: ReactNode;
  onClick?: () => void;
}[] = (isLightTheme) => [
  {
    label: '库存',
    path: '/stock',
    icon: <Stock />,
    iconActive: isLightTheme ? <StockActive /> : <StockActiveDarkTheme />,
  },
  {
    label: '使用中',
    path: '/in-use',
    icon: <InUse />,
    iconActive: isLightTheme ? <InUseActive /> : <InUseActiveDarkTheme />,
  },
  {
    label: '我的财务',
    path: '/my-finance',
    icon: <MyFinance />,
    iconActive: isLightTheme ? <MyFinanceActive /> : <MyFinanceActiveDarkTheme />,
  },
  {
    label: '设置',
    path: '/settings',
    icon: <Settings />,
    iconActive: isLightTheme ? <SettingsActive /> : <SettingsActiveDarkTheme />,
  },
  {
    label: '仪表盘',
    path: '/dashboard',
    icon: <Dashboard />,
    iconActive: isLightTheme ? <DashboardActive /> : <DashboardActiveDarkTheme />,
  },
];
